import { useNavigate } from "react-router-dom";
import { ProfileCard } from "../../components/elements/ProfileCard";
import { RoutePath } from "../../RoutePath";
import { teamList } from "./teamData";
import { Info } from "../../components/icons/Info";
import { Title } from "../../components/typography/Title";

export const Teams = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-[1440px] my-0 mx-auto">
      <div className="px-[16px] pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px] space-y-[24px]">
        <Title content="Članovi" bolded={true} />
        <p className="text-16">
          Redovnim članom Udruge može postati neprofitna pravna osoba, odnosno
          sportska udruga sa područja županija: Brodsko-posavske,
          Osječko-baranjske, Požeško-slavonske, Virovitičko-podravske i
          Vukovarsko-srijemske, koja u opisu svojih djelatnosti ima;
          organiziranje i provođenje biciklističkih sportskih natjecanja i
          priredbi i/ili sudjelovanje u biciklističkim sportskim natjecanjima i
          priredbama, koja udovoljava odredbama Pravilnika o članstvu, prihvaća
          Statut i druge akte Udruge i koja ima interes sudjelovati u radu
          Udruge i pružati doprinos ostvarivanju ciljeva Udruge.
        </p>
        <div className="flex gap-[8px] items-center">
          <Info className="text-alert-100" />
          <p className="text-16">
            Trenutno je 9 članova. Klikom na neki saznaj njegove detalje!{" "}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-[24px] justify-center justify-items-center">
          {teamList
            .sort((x, y) => x.order - y.order)
            .map((x, i) => (
              <ProfileCard
                key={i}
                firstName={x.name}
                imgUrl={x.img}
                lastName=""
                role={x.location}
                onClick={() => navigate(`${RoutePath.TEAMS}/${x.id}`)}
                size="small"
              />
            ))}
        </div>
      </div>
    </div>
  );
};
